import { API_URL } from "../../../constant"
import axios from "axios";
import {getAuthHeader} from '../../../services/config'
import s3Service from "../../../services/s3Service";

const baseRoute = "/api/modules"

export const createLecture = async (module) => {
   const response = await axios({ 
        baseURL: API_URL,
        method: "post",
        url: baseRoute,
        data: module,
       ...getAuthHeader()
    })
    
    return response.data
}

// export const createModuleItem = async (courseId, moduleId, moduleItem) => {
//    if (moduleItem instanceof FormData) {

//       const fileURL = await s3Service.uploadFile(
//         courseId,
//         'modules',
//         moduleItem.get('file').name,
//         moduleItem.get('file')
//       )

//       moduleItem.append('itemUrl', fileURL)
//     }

//     const response = await axios.post(
//       // /create-module-item/:courseId/:moduleId
//       `${API_URL}${baseRoute}/create-module-item/${courseId}/${moduleId}`,
//       moduleItem,
//       getAuthHeader()
//     )
//     return response.data.modules
// }

export const getAllLectures = async (course, module) => {
  const response = await axios({ baseURL: API_URL,
       method: "get",
       url: `${baseRoute}/items-by-module-id/${course}/${module}`,
      ...getAuthHeader()
     })
     
   return response.data
}

// export const getAllModuleItems = async (course, module) => {
//    const response = await axios({ 
//       baseURL: API_URL,
//       method: "get",
//       url: `${baseRoute}/items-by-module-id/${course}/${module}`,
//       ...getAuthHeader()
//    })
      
//     return response.data
//  }
 
