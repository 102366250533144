import React, { useState, useEffect } from 'react'

import { Typography, Modal, Input, List, Collapse, notification } from 'antd'
import {
  CaretRightOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { FlexSectionHeader } from '../shared/style'
import CourseCard from './cards/CourseCard'
import { getAllCourses } from './apis/courses'

import Spinner from '../shared/Spinner'

import { useHistory } from 'react-router-dom'
import CreateCourseModal from './modals/CreateCourseModal'

const { Title, Text } = Typography
const { confirm } = Modal

const StaffCourses = () => {
    const [loading, setLoading] = useState(false)
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(getAllCourses())
  // }, [dispatch])

  const user = {} // useSelector((state) => state.auth.user)
  // const courses = useSelector((state) =>
  //   state.courses.data.filter((course) => course.status !== 'archived')
  // )
  // const loading = useSelector((state) => state.courses.loading)
  const history = useHistory()
  const [courses, setCourses] = useState([
        
  ])

  const getCourses = () => {
    getAllCourses()
    .then(res=> {
        setCourses(res.data)
    }).catch(err => {
        notification.error(err.message)
    })
}

useEffect(() => getCourses(), [])

  const [filter, setFilter] = useState('')

  const filteredCourses = courses?.filter((course) => {
    return course.courseTitle.toLowerCase().indexOf(filter.toLowerCase()) > -1
  })

  const removeCourse = (courseId) => {
    // dispatch(deleteCourse(courseId))
  }

  const handleEnroll = (courseId, userId) => {
    // dispatch(enroll(courseId, userId))
  }

  const handleUnenroll = (courseId, userId) => {
    // dispatch(unEnroll(courseId, userId))
  }

  const handleSearch = (value) => {
    setFilter(value)
  }

  const confirmEnrolled = function (courseId, userId) {
    confirm({
      title: 'Do you Want to enroll in this course?',
      icon: <ExclamationCircleOutlined />,
      content: 'You have to enroll in the course to view its content',
      onOk() {
        handleEnroll(courseId, userId)
      },
      onCancel() {
        return
      }
    })
  }

  const handleCourseCardClick = (courseId, userId, userEnrolled) => {
    if (userEnrolled) history.push(`/courses/${courseId}/modules`)
    else confirmEnrolled(courseId, userId)
  }

  if (loading) return <Spinner size="large" />

  return (
    <React.Fragment>
      
      <FlexSectionHeader>
        {/* <Title level={3}>All Courses</Title> */}
        <Input.Search
          allowClear
          onSearch={handleSearch}
          placeholder="input search text"
          size="large"
          style={{ width: '300px', alignSelf: 'center' }}
        />
      </FlexSectionHeader>


      <div style={{ marginTop: '8px' }}>
        <Collapse
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          defaultActiveKey={['1']}
          ghost
        >
          <Collapse.Panel header={<Text strong>All Courses</Text>} key="1">

            <List
              grid={{
                gutter: 24,
                column: 3,
                xs: 1,
                sm: 2,
                xxl: 5
              }}
              dataSource={filteredCourses}
              renderItem={(course) => (
                <List.Item>
                  <CourseCard
                    course={course}
                    removeCourse={() => removeCourse(course.id)}
                    handleEnroll={() => handleEnroll(course.id, user._id)}
                    handleUnenroll={() => handleUnenroll(course.id, user._id)}
                    onClick={() =>
                      handleCourseCardClick(
                        course.id,
                        user._id,
                        course.enrolled
                      )
                    }
                  />
                </List.Item>
              )}
            />
          </Collapse.Panel>
        </Collapse>
      </div>
    </React.Fragment>
  )
}

export default StaffCourses
