import React, { useState } from 'react'
import {
    Col,
    Collapse,
    Empty,
    Form,
    Input,
    List,
    Modal,
    Row,
    Typography,
    notification
  } from 'antd'
import { createCourse } from '../apis/courses'
import { Button } from 'react-bootstrap'

function CreateCourseModal() {
    const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)

  const handleCancel = () => {
    setModalVisible(false)
  }

  const addCourse = (course) => {
    setLoading(true)
    createCourse(course).then(() => {
        notification.success('Course added successfully')
        setModalVisible(false)
    }).catch(err => {
        notification.error({
            message: "Couldn't add course"
          })
    }).finally(() => setLoading(false))
  }

  return (
    <>
        <Button
            onClick={() => setModalVisible(true)}
            type="dashed" loading={loading}
            shape="round"
            className='my-2'
            // icon={<PlusOutlined />}
          >
            Add Course
          </Button>
    <Modal
        title="Add New Course"
        visible={modalVisible}
        onOk={form.submit}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            Submit
          </Button>
        ]}
      >
        <Form
          name="add Course"
          form={form}
          onFinish={addCourse}
          requiredMark={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            name="courseTitle"
            label="Course Title"
            rules={[
              {
                required: true,
                message: 'Please enter the course name'
              }
            ]}
          >
            <Input placeholder="Course Name" />
          </Form.Item>

          <Form.Item name="courseDescription" label="Description">
            <Input.TextArea placeholder="(Optional)" allowClear />
          </Form.Item>

          <Form.Item name="image" label="Cover Image">
            <Input placeholder="(Optional) Image Url, defaults to random colour" />
          </Form.Item>
        </Form>
      </Modal>
      </>
  )
}

export default CreateCourseModal