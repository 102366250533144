import { API_URL } from "../../../constant"
import axios from "axios";
import {getAuthHeader} from '../../../services/config'

const baseRoute = "/api/courses"

export const createCourse = async (course) => {
   const response = await axios({ baseURL: API_URL,
        method: "post",
        url: baseRoute,
        data: course,
       ...getAuthHeader()
      })
    // const response = await axios.post(`${baseURL}`, course, getAuthHeader())
    return response.data
}

export const getAllCourses = async (course) => {
  const response = await axios({ baseURL: API_URL,
       method: "get",
       url: baseRoute,
       data: course,
      ...getAuthHeader()
     })
   // const response = await axios.post(`${baseURL}`, course, getAuthHeader())
   return response.data
 }

 export const getCourse = async (courseId) => {
  const response = await axios({ 
    baseURL: API_URL,
       method: "get",
       url: `${baseRoute}/${courseId}`,
      ...getAuthHeader()
     })
   // const response = await axios.post(`${baseURL}`, course, getAuthHeader())
   return response.data
 }



 
