export const getAuthHeader = () => {
    const token = `Bearer ${localStorage.getItem("token")}`
    return {
      headers: {
        Authorization: token
      }
    }
  }
  
  export const getMultiPartAuthHeader = () => {
    const token = JSON.parse(localStorage.getItem('token')).token
    return {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    }
  }
  
  export const getS3Credintials = () => ({
    accessKeyId: 'AKIARTJ33EZNEWZDE5QW',
    secretAccessKey: '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'
  })
  